const AUTH_ROUTES = Object.freeze({
  LOGIN: "/login",
  LOGOUT: "/logout",
});

/**
 * This is a map of all Authority Strings in Fusion. This should be a mirror of
 * the authorities contained in the Action table in the main Fusion db
 */
const AUTHORITIES = Object.freeze({
  RECS_REC_VIEW: "RECS_REC_VIEW",
  RECS_REC_TYPE_CONFIG_VIEW: "RECS_REC_TYPE_CONFIG_VIEW",
  RECS_ACCOUNT_VIEW: "RECS_ACCOUNT_VIEW",
  RECS_MATCHING_RULE_VIEW: "RECS_MATCHING_RULE_VIEW",
  RECS_GLOBAL_MAPPING_VIEW: "RECS_GLOBAL_MAPPING_VIEW",
  RECS_DATA_VERIFICATION_VIEW: "RECS_DATA_VERIFICATION_VIEW",

  RECS_VIEW_COLUMN_VIEW: "RECS_VIEW_COLUMN_VIEW",
  RECS_CREATE_COLUMN_VIEW: "RECS_CREATE_COLUMN_VIEW",
  RECS_DELETE_COLUMN_VIEW: "RECS_DELETE_COLUMN_VIEW",
  RECS_ROW_GROUP_ADD_NOTE: "RECS_ROW_GROUP_ADD_NOTE",
  RECS_ROW_GROUP_REMOVE_NOTE: "RECS_ROW_GROUP_REMOVE_NOTE",
  RECS_ROW_GROUP_ADD_COMMENT: "RECS_ROW_GROUP_ADD_COMMENT",
  RECS_ROW_GROUP_REMOVE: "RECS_ROW_GROUP_REMOVE",
  RECS_REC_SUBMIT: "RECS_REC_SUBMIT",
  RECS_REC_REOPEN: "RECS_REC_REOPEN",
  RECS_REC_APPROVE: "RECS_REC_APPROVE",
  RECS_REC_REJECT: "RECS_REC_REJECT",
  RECS_REC_SET_AS_DAY_ONE: "RECS_REC_SET_AS_DAY_ONE",
  RECS_REC_SKIP_TO_DATE: "RECS_REC_SKIP_TO_DATE",
  RECS_MATCHING_RULES_RUN: "RECS_MATCHING_RULES_RUN",
  RECS_ROW_GROUP_MANUALLY_CREATE: "RECS_ROW_GROUP_MANUALLY_CREATE",

  RECS_UPLOADED_FILE_VIEW: "RECS_UPLOADED_FILE_VIEW",
  RECS_UPLOADED_FILE_DELETE: "RECS_UPLOADED_FILE_DELETE",
  RECS_UPLOADED_FILE_ROWS_DELETE: "RECS_UPLOADED_FILE_ROWS_DELETE",
  RECS_UPLOADED_FILE_DOWNLOAD: "RECS_UPLOADED_FILE_DOWNLOAD",
  RECS_REC_UPLOAD_FILE: "RECS_REC_UPLOAD_FILE",

  RECS_GLOBAL_MAPPING_CREATE: "RECS_GLOBAL_MAPPING_CREATE",
  RECS_GLOBAL_MAPPING_DISABLE: "RECS_GLOBAL_MAPPING_DISABLE",
  RECS_GLOBAL_MAPPING_ENABLE: "RECS_GLOBAL_MAPPING_ENABLE",
  RECS_GLOBAL_MAPPING_APPROVE: "RECS_GLOBAL_MAPPING_APPROVE",
  RECS_GLOBAL_MAPPING_REJECT: "RECS_GLOBAL_MAPPING_REJECT",
  RECS_GLOBAL_MAPPING_ENTRY_CREATE: "RECS_GLOBAL_MAPPING_ENTRY_CREATE",
  RECS_GLOBAL_MAPPING_ENTRY_APPROVE: "RECS_GLOBAL_MAPPING_ENTRY_APPROVE",
  RECS_GLOBAL_MAPPING_ENTRY_REJECT: "RECS_GLOBAL_MAPPING_ENTRY_REJECT",

  RECS_DATA_VERIFICATION_CREATE: "RECS_DATA_VERIFICATION_CREATE",
  RECS_DATA_VERIFICATION_DELETE: "RECS_DATA_VERIFICATION_DELETE",

  RECS_MATCHING_RULE_CREATE: "RECS_MATCHING_RULE_CREATE",
  RECS_MATCHING_RULE_DELETE: "RECS_MATCHING_RULE_DELETE",
  RECS_MATCHING_RULE_ACCOUNT_ENABLE: "RECS_MATCHING_RULE_ACCOUNT_ENABLE",
  RECS_MATCHING_RULE_REORDER: "RECS_MATCHING_RULE_REORDER",

  RECS_ACCOUNT_CREATE: "RECS_ACCOUNT_CREATE",
  RECS_ACCOUNT_EDIT: "RECS_ACCOUNT_EDIT",
  RECS_CLIENT_CREATE: "RECS_CLIENT_CREATE",
  RECS_ACCOUNT_REC_TYPE_ENABLE: "RECS_ACCOUNT_REC_TYPE_ENABLE",
  RECS_ACCOUNT_REC_TYPE_DISABLE: "RECS_ACCOUNT_REC_TYPE_DISABLE",
  RECS_ACCOUNT_GLOBAL_MAPPING_ENABLE: "RECS_ACCOUNT_GLOBAL_MAPPING_ENABLE",
  RECS_ACCOUNT_GLOBAL_MAPPING_DISABLE: "RECS_ACCOUNT_GLOBAL_MAPPING_DISABLE",
  RECS_ACCOUNT_SUB_ACCOUNT_ENABLE: "RECS_ACCOUNT_SUB_ACCOUNT_ENABLE",
  RECS_ACCOUNT_SUB_ACCOUNT_REMOVE: "RECS_ACCOUNT_SUB_ACCOUNT_REMOVE",
  RECS_FUND_IDENTIFIER_ADD: "RECS_FUND_IDENTIFIER_ADD",
  RECS_FUND_IDENTIFIER_REMOVE: "RECS_FUND_IDENTIFIER_REMOVE",
  RECS_FUND_ALIAS_ADD: "RECS_FUND_ALIAS_ADD",
  RECS_FUND_ALIAS_REMOVE: "RECS_FUND_ALIAS_REMOVE",

  RECS_SUB_ACCOUNT_CREATE: "RECS_SUB_ACCOUNT_CREATE",
  RECS_SUB_ACCOUNT_DELETE: "RECS_SUB_ACCOUNT_DELETE",
  RECS_SUB_ACCOUNT_VIEW: "RECS_SUB_ACCOUNT_VIEW",
  RECS_SUB_ACCOUNT_CODE_ADD: "RECS_SUB_ACCOUNT_CODE_ADD",
  RECS_SUB_ACCOUNT_CODE_REMOVE: "RECS_SUB_ACCOUNT_CODE_REMOVE",

  RECS_REC_TYPE_CREATE: "RECS_REC_TYPE_CREATE",
  RECS_REC_TYPE_AUTOCOMPLETE_ENABLE: "RECS_REC_TYPE_AUTOCOMPLETE_ENABLE",
  RECS_REC_TYPE_REC_APPROVAL_ENABLE: "RECS_REC_TYPE_REC_APPROVAL_ENABLE",

  RECS_CARRY_AUTO_GROUP_CREATE: "RECS_CARRY_AUTO_GROUP_CREATE",
  RECS_CARRY_MANUAL_GROUP_CREATE: "RECS_CARRY_MANUAL_GROUP_CREATE",
  RECS_CARRY_GROUP_VIEW: "RECS_CARRY_GROUP_VIEW",
  RECS_CARRY_AUTO_GROUP_DELETE: "RECS_CARRY_AUTO_GROUP_DELETE",
  RECS_CARRY_AUTO_GROUP_ENABLE: "RECS_CARRY_AUTO_GROUP_ENABLE",
  RECS_CARRY_MANUAL_GROUP_DELETE: "RECS_CARRY_MANUAL_GROUP_DELETE",
  RECS_CARRY_MANUAL_GROUP_ENABLE: "RECS_CARRY_MANUAL_GROUP_ENABLE",
  RECS_REC_TYPE_CARRY_FORWARD_VIEW: "RECS_REC_TYPE_CARRY_FORWARD_VIEW",
  RECS_REC_TYPE_CARRY_FORWARD_ENABLE: "RECS_REC_TYPE_CARRY_FORWARD_ENABLE",
});

const MODALS = Object.freeze({
  CALENDAR: "reconciliations-calendar-modal",
  UPLOADED_FILES: "reconciliations-uploaded-files-modal",
  ADD_NOTE: "reconciliations-add-note-modal",
  DELETE: "delete-modal",
  WARNING: "warning-modal",
  RECTYPE_SETUP: "rectype-setup",
  REC_TYPE_SETTINGS: "rec-type-settings",
  CREATE_RECTYPE: "create-rectype",
  COLUMN_MAPPING: "column-mapping",
  ACCOUNT_SETUP: "account-setup",
  CREATE_CLIENT: "create-client",
  CREATE_FUND_IDENTIFIER: "create-fund-identifier",
  ENABLE_SUB_ACCOUNT: "enable-sub-account",
  ADD_SUBACCOUNT_FUND_ALIAS: "add-fund-subaccount-alias",
  CREATE_DATA_VERIFICATION_RULE: "create-data-verification-rule",
  DATA_VERIFICATION_RULE_BUILDER: "data-verification-rule-builder",
  CREATE_MATCHING_RULE: "create-matching-rule",
  MATCHING_RULE_BUILDER: "matching-rule-builder",
  SUB_ACCOUNT_SETUP: "sub-account-setup",
  CREATE_SUB_ACCOUNT_CODE: "create-sub-account-code",
  RUN_ALL_MATCHING_RULES: "run-all-matching-rules",
  RUN_SELECTED_MATCHING_RULES: "run-selected-matching-rules",
  CREATE_MANUAL_MATCH: "create-manual-match",
  COLUMN_VIEWS: "column-views",
  APPLY_MATCHING_RULES: "apply-matching-rules",
  DELETE_SUB_ACCOUNT: "delete-sub-account",
  MANUAL_UPLOAD: "manual-upload",
  CARRY_FORWARD_MANUAL_ACTIONS: "carryForwardManualActions",
  CARRY_FORWARD_TAGS_COMMENTS: "carryForwardTagsComments",
  CARRY_FORWARD_ROWS: "carryForwardRows",
  SET_AS_DAY_ONE: "setAsDayOne",
  SKIP_TO_DATE: "skipToDate",
  REOPEN: "reopen",
});

const NOTE_TYPE = Object.freeze({
  CATEGORY: "CATEGORY",
  VERIFICATION: "VERIFICATION",
  TAG: "TAG",
});

const CARRY_FORWARD_TYPE = Object.freeze({
  CARRY_FORWARD_MANUAL_ACTIONS: "CARRY_FORWARD_MANUAL_ACTIONS",
  CARRY_FORWARD_TAGS_COMMENTS: "CARRY_FORWARD_TAGS_COMMENTS",
  CARRY_FORWARD_ROW_GROUPS: "CARRY_FORWARD_ROW_GROUPS",
});

const MATCH_PREVIEW_TYPE = Object.freeze({
  MANUAL_CATEGORIZATION: "manualCategorization",
  SELECTED_MATCHING_RULE: "selectedMatchingRule",
});

const MIME_TYPE = Object.freeze({
  CSV: "text/plain;charset=UTF-8",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  XML: "application/xml",
  PDF: "application/pdf",
  DEFAULT: "application/octet-stream",
});

const REC_TYPE_CONFIG_VIEWS = Object.freeze({
  GLOBAL_MAPPING_VIEW: "globalMapping",
  AUTO_COMPLETE_VIEW: "autoComplete",
  MANUAL_MATCHING_VIEW: "manualMatching",
  CARRY_FORWARD_ROWS_VIEW: "carryForwardRows",
  REPEAT_MANUAL_ACTIONS_VIEW: "repeatManualActions",
  AUTO_UPDATE_COMMENTS_VIEW: "autoUpdateComments",
});

const REC_TYPE_TYPES = Object.freeze({
  SEQUENTIAL: "SEQUENTIAL",
  AD_HOC: "AD_HOC",
});

const STATUS = Object.freeze({
  OPEN: { status: "OPEN", text: "Open", backgroundColor: "#E9EFFF", color: "#1E5EFF", lightColor: "#F4F7FF", width: "80px" },
  DRAFT: { status: "DRAFT", text: "Draft", backgroundColor: "#E9EFFF", color: "#1E5EFF", lightColor: "#F4F7FF", width: "80px" },
  PENDING: { status: "PENDING", text: "Pending", backgroundColor: "#FEF5E6", color: "#C77800", lightColor: "#FFFAF2", width: "80px" },
  APPROVED: { status: "APPROVED", text: "Approved", backgroundColor: "#E8F6EE", color: "#15A251", lightColor: "#F3FAF6", width: "80px" },
  DELETED: { status: "DELETED", text: "Deleted", backgroundColor: "#6A7388", color: "#E6E8EB", width: "80px" },
  TRASH: { status: "TRASH", text: "Trash", backgroundColor: "#394561", color: "#E6E8EB", width: "80px" },
  IN_PROGRESS: { status: "IN_PROGRESS", text: "In progress", backgroundColor: "#FEF5E6", color: "#C77800", width: "80px" },
  IMPORTED: { status: "IMPORTED", text: "Imported", backgroundColor: "#E8F6EE", color: "#15A251", width: "80px" },
  FAILED: { status: "FAILED", text: "Failed", backgroundColor: "#FEE8EA", color: "#F0142F", width: "80px" },
  PUBLISHED: { status: "PUBLISHED", text: "Published", backgroundColor: "#E8F6EE", color: "#15A251", width: "80px" },
  REPORT_CREATE: { status: "REPORT_CREATE", text: "Creating report", backgroundColor: "#FEF5E6", color: "#C77800", width: "120px" },
  REPORT_CLEAR: { status: "REPORT_CLEAR", text: "Reopening", backgroundColor: "#FEF5E6", color: "#C77800", width: "80px" },
  SENT_TO_DATA: { status: "SENT_TO_DATA", text: "Sent to Fusion Data", backgroundColor: "#FEF5E6", color: "#C77800", width: "160px" },
  IMPORTING_DATA: { status: "IMPORTING_DATA", text: "Importing data", backgroundColor: "#FEF5E6", color: "#C77800", width: "120px" },
  RESTRICTED: { status: "RESTRICTED", text: "Restricted", backgroundColor: "#F3F3F5", color: "#394561", lightColor: "#F7F8F9", width: "80px" },
  SKIPPED: { status: "SKIPPED", text: "Skipped", backgroundColor: "#F3F3F5", color: "#394561", lightColor: "#F7F8F9", width: "80px" },
  CANCELLED: { status: "CANCELLED", text: "Cancelled", backgroundColor: "#F3F3F5", color: "#394561", lightColor: "#F7F8F9", width: "80px" },
  IGNORED: { status: "IGNORED", text: "Ignored", backgroundColor: "#F3F3F5", color: "#394561", lightColor: "#F7F8F9", width: "80px" },
});

// Name for a hidden column used for row group categories
const CATEGORY_FILTER_COLUMN = "categoryFilterColumn";

// Name for a hidden column used for row group categories
const CARRIED_FORWARD_COLUMN = "carriedForward";

export {
  AUTH_ROUTES,
  AUTHORITIES,
  MODALS,
  NOTE_TYPE,
  MATCH_PREVIEW_TYPE,
  MIME_TYPE,
  REC_TYPE_CONFIG_VIEWS,
  STATUS,
  CATEGORY_FILTER_COLUMN,
  CARRY_FORWARD_TYPE,
  REC_TYPE_TYPES,
  CARRIED_FORWARD_COLUMN
};
