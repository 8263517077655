import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Text, Heading, R, C3, C4, C6, C8, Button, LinkText, HyperLink } from "@fundrecs/ui-library";
import { getDayAndTimeString } from "../../utils/dates.js";
import { useStore } from "../../store/Store.js";
import { PATH } from "../../utils/urls.js";
import { createBrowserDownload } from "../../utils/file.js";
import { MIME_TYPE, STATUS } from "../../utils/enums.js";
import { StatusBadge } from "../reusable/StatusBadge.js";
import { Spinner } from "../layout/Spinner.js";

const TextData = ({ name, data }) => {
  return (
    <R props="mt-8">
      <C3 props="pl-0">
        <Text size="xs" weight="regular">
          {name}
        </Text>
      </C3>
      <C8>
        <Text size="xs" weight="regular">
          {data}
        </Text>
      </C8>
    </R>
  );
};

const ComponentData = ({ name, component }) => {
  return (
    <R props="mt-8">
      <C3 props="pl-0">
        <Text size="xs" weight="regular">
          {name}
        </Text>
      </C3>
      <C8>{component}</C8>
    </R>
  );
};

const CountData = ({ name, data }) => {
  return (
    <R props="mt-8">
      <C6 props="pl-0">
        <Text size="xs" weight="regular">
          {name}
        </Text>
      </C6>
      <C6>
        <Text size="xs" weight="regular">
          {data}
        </Text>
      </C6>
    </R>
  );
};

const UploadInfo = ({ selectedFile }) => {
  const { teamStore, tenantConfigStore, uploadedFileStore } = useStore();
  const fusionDataUrl = tenantConfigStore.getFusionDataUrl();
  const teamId = teamStore.getSelectedTeam().id;

  const downloadFile = async (selectedFile) => {
    const fileExt = selectedFile.fileName.split(".").pop().toUpperCase();
    const responseType = ["PDF", "XLS", "XLSX"].includes(fileExt) ? "arraybuffer" : "json";
    const response = await uploadedFileStore.downloadFile(teamId, selectedFile.fileId, responseType);
    if (response.status === 200) {
      const mimeType =
        fileExt === "PDF"
          ? MIME_TYPE.PDF
          : fileExt === "CSV"
          ? MIME_TYPE.CSV
          : fileExt === "XLSX"
          ? MIME_TYPE.XLSX
          : fileExt === "XML"
          ? MIME_TYPE.XML
          : MIME_TYPE.DEFAULT;
      createBrowserDownload(selectedFile.fileName, response.data, mimeType);
    }
  };

  return (
    <>
      <ComponentData
        name={"Original File:"}
        data={selectedFile.fileName}
        component={
          <Text size="xs" weight="regular">
            <span
              onClick={() => {
                downloadFile(selectedFile);
              }}
            >
              <LinkText>{selectedFile.fileName}</LinkText>
            </span>
          </Text>
        }
      />
      <ComponentData
        name={"Workflow:"}
        component={
          <Text size="xs" weight="regular">
            <HyperLink href={`${fusionDataUrl}${PATH.WORKFLOWS}/${selectedFile.workflowId}?teamId=${teamId}`}>
              <LinkText>{selectedFile.workflowName}</LinkText>
            </HyperLink>
          </Text>
        }
      />
      <TextData name={"Template:"} data={selectedFile.templateName} />
      <TextData name={"Uploaded by:"} data={selectedFile.uploadedBy} />
    </>
  );
};

const TimesInfo = ({ selectedFile }) => {
  return (
    <>
      <ComponentData name={"Status:"} component={<StatusBadge status={selectedFile.fileStatus} uploadedFileStatus={true} />} />
      <TextData name={"Start Time:"} data={getDayAndTimeString(new Date(selectedFile.startTime))} />
      <TextData name={"End Time:"} data={selectedFile.endTime ? getDayAndTimeString(new Date(selectedFile.endTime)) : "-"} />
      <TextData name={"Time taken (secs):"} data={selectedFile.timeTaken ? `${selectedFile.timeTaken} seconds` : "-"} />
    </>
  );
};

const CountsInfo = ({ selectedFile }) => {
  return (
    <>
      <CountData name={"No. of recs:"} data={selectedFile.recCount} />
      <CountData name={"Total Row Count:"} data={selectedFile.totalRowCount} />
      <CountData name={"Imported Row Count:"} data={selectedFile.importedRowCount} />
      <CountData name={"Rows to be processed:"} data={selectedFile.inProgressRowCount ? selectedFile.inProgressRowCount : "-"} />
    </>
  );
};

const FileImportSummary = observer(({ selectedFile, columnNumber = 1, teamId }) => {
  return (
    <>
      <div className="mt-32"></div>
      <div className="d-flex">
        <Heading variant="h5">Import summary</Heading>
        {selectedFile.fileStatus === STATUS.SENT_TO_DATA.status ? <Spinner color="white" backgroundColor="red" /> : ""}
      </div>
      {columnNumber === 3 ? (
        <R props="mt-16 pl-0">
          <C4>
            <UploadInfo selectedFile={selectedFile} />
          </C4>
          <C4>
            <TimesInfo selectedFile={selectedFile} />
          </C4>
          <C4>
            <CountsInfo selectedFile={selectedFile} />
            <R props="mt-8">
              <C6 props="pl-0">
                <Text size="xs" weight="regular">
                  No. of Errors:
                </Text>
              </C6>
              <C6>
                <Text size="xs" weight="regular">
                  {selectedFile.importErrorCount}
                </Text>
                <span className="ml-8"></span>
                <Link className="text-decoration-none" to={`${PATH.UPLOADED_FILE_ERRORS}?teamId=${teamId}&fileId=${selectedFile.fileId}`}>
                  <Button color="primary-secondary" onClick={() => {}} size="sm">
                    <Text weight="regular" size="xs">
                      View Errors
                    </Text>
                  </Button>
                </Link>
              </C6>
            </R>
          </C4>
        </R>
      ) : (
        <>
          <UploadInfo selectedFile={selectedFile} />
          <TimesInfo selectedFile={selectedFile} />
          <CountsInfo selectedFile={selectedFile} />
          <CountData name={"No. of Errors:"} data={selectedFile.importErrorCount} />
        </>
      )}
    </>
  );
});

export { FileImportSummary };
