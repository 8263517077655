import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import "./recSidePanel.css";

const RecSidePanel = observer(({ rowGroupDetailsOpen, recDetailsOpen, includeRowGroupDetails = true, children }) => {
  const [initialised, setInitialised] = useState(false);

  const sidePanel = document.getElementById("recSidePanel");
  const rowGroupDetailsPanel = document.getElementById("rowGroupDetailsPanel");
  const recDetailsPanel = document.getElementById("recDetailsPanel");
  const layoutElement = document.getElementsByClassName("baselayout-main")[0];
  const buttonElement = document.getElementById("sidePanelButton");
  const WIDTHS = { 0: "0px", 45: "45px", 60: "60px", 385: "385px", 400: "400px", 460: "460px", 800: "800px" };

  //When the page loads, set the margin for baselayout
  if (!initialised && layoutElement) {
    layoutElement.style.marginRight = WIDTHS[60];
    setInitialised(true);
  }

  //When the component unmounts, reset the margin for baselayout to ensure other pages aren't affected
  useEffect(
    () => () => {
      const baseLayout = document.getElementsByClassName("baselayout-main")[0];
      if (baseLayout) {
        baseLayout.style.marginRight = WIDTHS[0];
      }
    },
    []
  );

  if (sidePanel && recDetailsPanel && layoutElement && buttonElement && (includeRowGroupDetails ? rowGroupDetailsPanel : true)) {
    if (rowGroupDetailsOpen && recDetailsOpen) {
      sidePanel.style.width = WIDTHS[800];
      layoutElement.style.marginRight = WIDTHS[800];
      buttonElement.style.right = WIDTHS[385];

      rowGroupDetailsPanel.style.width = WIDTHS[400];
      rowGroupDetailsPanel.style.visibility = "visible";
      rowGroupDetailsPanel.style.right = WIDTHS[400];

      recDetailsPanel.style.width = WIDTHS[400];
    } else if (rowGroupDetailsOpen && !recDetailsOpen) {
      sidePanel.style.width = WIDTHS[460];
      layoutElement.style.marginRight = WIDTHS[460];
      buttonElement.style.right = WIDTHS[45];

      rowGroupDetailsPanel.style.width = WIDTHS[400];
      rowGroupDetailsPanel.style.visibility = "visible";
      rowGroupDetailsPanel.style.right = WIDTHS[60];

      recDetailsPanel.style.width = WIDTHS[60];
    } else if (!rowGroupDetailsOpen && recDetailsOpen) {
      sidePanel.style.width = WIDTHS[400];
      layoutElement.style.marginRight = WIDTHS[400];
      buttonElement.style.right = WIDTHS[385];

      if (rowGroupDetailsPanel) {
        rowGroupDetailsPanel.style.width = WIDTHS[0];
        rowGroupDetailsPanel.style.visibility = "hidden";
        rowGroupDetailsPanel.style.right = WIDTHS[60];
      }

      recDetailsPanel.style.width = WIDTHS[400];
    } else if (!rowGroupDetailsOpen && !recDetailsOpen) {
      sidePanel.style.width = WIDTHS[60];
      layoutElement.style.marginRight = WIDTHS[60];
      buttonElement.style.right = WIDTHS[45];

      if (rowGroupDetailsPanel) {
        rowGroupDetailsPanel.style.width = WIDTHS[0];
        rowGroupDetailsPanel.style.visibility = "hidden";
        rowGroupDetailsPanel.style.right = WIDTHS[40];
      }

      recDetailsPanel.style.width = WIDTHS[60];
    }
  }

  return (
    <div id="recSidePanel" className="side-panel-style">
      {children}
    </div>
  );
});

export { RecSidePanel };
